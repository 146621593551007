import qs from 'qs';

function getAPIBaseUrl(){
  // return 'http://157.245.130.58/'
  return '';
}

const API_BASE = getAPIBaseUrl();
const opts = { credentials:'include' }

function getITunesResults(term){
  return fetch(`${API_BASE}/api/podcast/search/${term}`, opts)
    .then((res) => res.json())
    .then((data) => data.results)
}

function getPodcast(id, params) {
  const URL = `${API_BASE}/api/podcast/${id}?${qs.stringify(params)}`;
  return fetch(URL, {
    ...opts,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  })
  .then(res => res.json())
}

function postAudioSource(body){

  const URL = `${API_BASE}/api/podcast/`;
  return fetch(URL, {
    ...opts,
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  })
  .then(res => res.json())
}

export default {
  API_BASE,
  getITunesResults,
  getPodcast,
  postAudioSource
}
