import React, { Component } from 'react';

import { Colors } from '../baseStyles';

const styles = {
  container: {
    minHeight:60,
    borderTop: "1px solid "+Colors.text,
    cursor: "pointer",
    color: Colors.text,
  },
  hover: {
    // backgroundImage: `linear-gradient(-180deg, ${Colors.gradients.blue.start} 0%, ${Colors.gradients.blue.end} 100%)`,
    backgroundColor: Colors.button,
    color:"#FFF",
  },
  image: {
    width:60,
    height:60,
    float:"left"
  },
  details: {
    padding: "5px 15px 5px 70px"
  },
  title: {
    fontSize: "11px",
    lineHeight: "12px",
    marginBottom:"2px",
    fontWeight:"bold",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  artist: {
    fontSize:"11px",
    marginBottom:6,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  meta: {
    fontSize: 11,
    opacity: 0.65,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  }
};

export default class extends Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(){
    if(this.props.onSelect) this.props.onSelect(this.props.data);
  }

  render() {
    return (
      <div
        onMouseEnter={this.props.onHover}
        style={{ ...styles.container, ...(this.props.active && !this.props.noHover ? styles.hover : {}), ...this.props.style }}
        onClick={this.onClick} >
        { this.props.data.artworkUrl60 &&
          <div style={ styles.image }><img src={this.props.data.artworkUrl60} alt="podcast artwork" /></div>
        }
        <div style={ styles.details }>
          <div style={styles.title}>{ this.props.data.collectionCensoredName }</div>
          <div style={styles.artist}>{ this.props.data.artistName }</div>
          <div style={styles.meta}>
            <span>{ this.props.data.genres.join(", ") }</span>
          </div>
        </div>
      </div>
    )
  }
}
