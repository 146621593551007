
import React, { Component } from 'react';
import ReactGA from 'react-ga';
// import nlp from 'compromise';

import ITunesSearch from './components/ITunesSearch';
import PodcastList from './components/PodcastList';
import PodcastCitation from './components/Citation'

import ApiService from './services/ApiService';

import { Colors, InputStyles, TextStyles, ContainerStyles, ButtonStyles } from './baseStyles';

export default class PodcastCitationWizard extends Component {
  constructor(props){
    super(props);
    this.defaultSpeaker = {
      first:"",
      last:"",
      middle:"",
      title:"",
      complete:false
    };

    this.defaultState = {
      podcast: null,
      episode: null,
      speaker: {
        ...this.defaultSpeaker
      },
      episodeList: null,
      style: "APA",
      url: null,
      errors:null,
      loading: false,
      clipped: false
    }

    this.state = this.defaultState;

    this.reset = this.reset.bind(this);
    this._onItunesSelect = this._onItunesSelect.bind(this);
  }

  reset(){
    ReactGA.event({
      category: 'User',
      action: 'Creating New Citation'
    });

    this.setState({
      ...this.defaultState,
      speaker: {
          ...this.defaultSpeaker
      }
    });
  }

  render(){
    if(!this.state.podcast) return this._renderPodcastSearch();
    if(!this.state.episode) return this._renderEpisodes();
    if(!this.state.speaker.complete) return this._renderSpeakerSelect();
    return this._renderCitation()
  }

  _renderPodcastSearch(){
    return (
      <div style={ContainerStyles.appContainer}>
        <div style={ContainerStyles.default}>
          <p style={TextStyles.text}>Cite your podcast in MLA 8, APA and Chicago formats.<br/>
          3 easy steps, select the podcast, select the episode and enter the speaker's name.</p>
        </div>
        <div style={{...ContainerStyles.panel }}>
          <p style={TextStyles.panelText}>To get started, use the search tools below:</p>
          <div style={{
            borderRadius:6,
            background: Colors.panelHighlight,
            position:'relative'
          }}>
            <ITunesSearch onSelect={this._onItunesSelect} />
            { this.state.loading &&
              <div style={{
                position:"absolute",
                top:0, bottom:0, right:0, left:0,
                background:'rgba(255, 255, 255, 0.1)'
              }}/>

            }

          </div>
          { this.state.loading &&
            <div style={{...TextStyles.text, fontSize:'85%', fontWeight:'bold',marginTop:15, color:'#FFF', display:'flex', justifyContent:'center'}}>
              Loading Podcast Feed
              <div className="spinner" style={{marginLeft:5}}>
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          }
        </div>
        { false && <div>OR MANUAL ENTRY FLOW</div> }
      </div>
    )
  }

  _onItunesSelect(podcast){
    const url = podcast.feedUrl, itunesId = podcast.collectionId;
    ReactGA.event({
      category: 'User',
      action: 'Selected a Podcast',
      label: podcast.collectionName
    });

    this.setState({ url, itunesId }, () => { this._submit("itunes") });
  }

  _submit() {

    let {url, itunesId} = this.state;
    this.setState({loading:true});
    ApiService.postAudioSource({url, itunesId})
      .then(data => {
        this.setState({loading:false, podcast:data.rss});
      })
      .catch((err) => {
        this.setState({errors:[ err ], loading:false});
        return;
      });
  }

  _renderEpisodes(){
    return (
      <div>
        { false && this._renderPodcastHeader() }
        <PodcastList podcast={ {rss:this.state.podcast} } onSelect={(episode) => {
          ReactGA.event({
            category: 'User',
            action: 'Selected an Episode',
            label: episode.title
          });
          this.setState({episode})
        }}/>
      </div>)
  }

  _renderSpeakerSelect(){

    return (
      <div style={{maxWidth:'800px', margin:'0 auto', padding:'32px 16px'}}>
        <div style={{...ContainerStyles.panel, ...TextStyles.panelText}}>
          Please enter the name of the speaker you're citing below:
          <div style={{display:'flex', flexWrap:'wrap', marginLeft:-10, marginRight:-10}}>
            <div style={ContainerStyles.inputContainer}>
              <label htmlFor="speaker_first" style={{display:'block'}}>First Name:</label>
              <input style={{...InputStyles.default, width:'100%', display:'block'}} type="text" name="speaker_first" value={this.state.speaker.first}
                onChange={(e) => {
                  let { speaker } = this.state;
                  speaker.first = e.target.value;
                  this.setState({ speaker })
                }} />
            </div>
            <div style={ContainerStyles.inputContainer}>
              <label htmlFor="speaker_first" style={{display:'block'}}>Middle Names (optional):</label>
              <input style={{...InputStyles.default, width:'100%', display:'block'}} type="text" name="speaker_first" value={this.state.speaker.middle}
                onChange={(e) => {
                  let { speaker } = this.state;
                  speaker.middle = e.target.value;
                  this.setState({ speaker })
                }} />
            </div>
            <div style={ContainerStyles.inputContainer}>
              <label htmlFor="speaker_first" style={{display:'block'}}>Last Name:</label>
              <input style={{...InputStyles.default, width:'100%', display:'block'}} type="text" name="speaker_first" value={this.state.speaker.last}
                onChange={(e) => {
                  let { speaker } = this.state;
                  speaker.last = e.target.value;
                  this.setState({ speaker })
                }} />
            </div>
            <div style={ContainerStyles.inputContainer}>
              <label htmlFor="speaker_first" style={{display:'inline'}}>Title<sup>1</sup>:</label><span style={{
                marginLeft: 10, fontSize: '80%', opacity: 0.7
              }}>(APA only)</span>
              <input style={{...InputStyles.default, width:'100%', display:'block'}} type="text" name="speaker_first" value={this.state.speaker.title}
                onChange={(e) => {
                  let { speaker } = this.state;
                  speaker.title = e.target.value;
                  this.setState({ speaker })
                }} />
            </div>
          </div>
          <div style={{...ButtonStyles.default}}  onClick={() => {
            let { speaker } = this.state;
            speaker.complete = true;

            ReactGA.event({
              category: 'User',
              action: 'Added speaker details',
              label: `${speaker.first} ${speaker.middle} ${speaker.last} ${speaker.title}`
            });

            this.setState({ speaker })
          }}>Continue</div>
        </div>
        <div><ol><li>Example: Host, Guest, Producer, Narrarator, etc</li></ol></div>
        <div style={{marginTop:25}}>
          <div style={{...TextStyles.text, fontWeight:'bold'}}>Episode Summary:</div>
          <div style={{...TextStyles.text}} dangerouslySetInnerHTML={{__html:this.state.episode.summary}}></div>
        </div>
    </div>)

  }

  _renderCitation(){

    return (
      <div style={{
        ...ContainerStyles.default,
        maxWidth:'800px',
        margin:'0 auto',
        padding:'32px 16px'
      }}>
        <label style={TextStyles.text}>Choose a citation format:</label>
        <div style={ButtonStyles.buttonGroup}>
          <div style={{
            ...ButtonStyles.buttonGroupButton,
            ...(this.state.style === "APA") ? ButtonStyles.buttonGroupSelected : {}
          }} onClick={() => {
            ReactGA.event({
              category: 'User',
              action: 'Changed Cite Format',
              label: "APA"
            });
            this.setState({style:"APA"})
          }}>APA</div>
          <div style={{
            ...ButtonStyles.buttonGroupButton,
            borderLeft: `2px solid ${Colors.buttonText}`,
            borderRight: `2px solid ${Colors.buttonText}`,
            ...(this.state.style === "Chicago") ? ButtonStyles.buttonGroupSelected : {}
          }} onClick={() => {
            ReactGA.event({
              category: 'User',
              action: 'Changed Cite Format',
              label: "Chicago"
            });
            this.setState({style:"Chicago"})
          }}>Chicago</div>
          <div style={{
            ...ButtonStyles.buttonGroupButton,
          ...(this.state.style === "MLA 8") ? ButtonStyles.buttonGroupSelected : {}
        }} onClick={() => {
          ReactGA.event({
            category: 'User',
            action: 'Changed Cite Format',
            label: "MLA 8"
          });
          this.setState({style:"MLA 8"})
        }}>MLA 8</div>
        </div>

        <div style={{...TextStyles.text, marginTop:25}}>Here's your {this.state.style} citation:</div>
        <div style={{background:'#f5f5f5', borderRadius:6, padding:12}}>
          <PodcastCitation
            style={this.state.style}
            speaker={this.state.speaker}
            date={this.state.episode}
            podcast={this.state.podcast.channel.title}
            publisher={this.state.podcast.channel.author}
            episode={this.state.episode.title}
            url={this.state.episode.url}
          />
        </div>

        <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-evenly", marginTop:15}}>
          <div style={{ ...ButtonStyles.default, width:180, textAlign:'center'}} onClick={() => {

            var range = document.createRange();
            range.selectNode(document.getElementById("citation"));
            window.getSelection().removeAllRanges(); // clear current selection
            window.getSelection().addRange(range); // to select text
            document.execCommand("copy");
            window.getSelection().removeAllRanges();// to deselect
            this.setState({clipped:true}, () => {
              setTimeout(function(){
                this.setState({clipped:false})
              }.bind(this), 5000);
            })
            ReactGA.event({
              category: 'User',
              action: 'Copied Citation'
            });
          }}>{this.state.clipped ? "Copied!" : "Copy to Clipboard"}</div>
          <div style={{ ...ButtonStyles.default, width:180, textAlign:'center'}} onClick={this.reset}>New Citation</div>
        </div>
      </div>
    )

  }
}
