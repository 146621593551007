import React, { Component } from 'react';

import debounce from 'debounce';

import ApiService from '../services/ApiService';

import SearchResult from './iTunesSearchResult';

import IconSearch from '../resources/images/icon-search.svg';

import { InputStyles, Colors } from '../baseStyles';

const styles = {
  autocompleteCnt:{
    position:"absolute",
    border:"1px solid "+Colors.text,
    maxHeight:"50vh",
    overflowY:"scroll",
    top:55,
    right:0,
    left:0,
    zIndex:1,
    background:"#fff"
  },
  itunesSearch: {
    ...InputStyles.default,
    padding:10,
    backgroundImage: `url(${IconSearch})`,
    backgroundRepeat:"no-repeat",
    backgroundSize: "16px",
    backgroundPosition: "10px center",
    paddingLeft:38,
    outline: 'none'
    // width:392
  }
}


export default class extends Component {

  constructor() {
    super();

    this.state = {
      activeIndex: null,
      itunesSeachTerm: ""
    }

    this._onSelect = this._onSelect.bind(this);
    this._handleItunesTermChange = this._handleItunesTermChange.bind(this);
    this._preformItunesSearch = debounce(this._preformItunesSearch.bind(this), 500);
    this._handleKeyDown = this._handleKeyDown.bind(this);
  }

  componentWillMount(){
    document.addEventListener("keydown", this._handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }

  _handleKeyDown (event) {
    // let { activeIndex } = this.state;
    // switch( event.keyCode ) {
    //     case KEY_CODES.UP_ARROW: //UP ARROW
    //       activeIndex = (activeIndex !== null) ? activeIndex-1 : activeIndex;
    //       this.setState({activeIndex})
    //       break;
    //     case KEY_CODES.DOWN_ARROW: //DOWN ARROW
    //       activeIndex = (activeIndex !== null) ? activeIndex+1 : 0;
    //       this.setState({activeIndex})
    //       break;
    //     case KEY_CODES.ENTER:
    //       this._onSelect(this.state.itunesResults[activeIndex]);
    //       break;
    //     case KEY_CODES.ESCAPE:
    //       this.setState({itunesSeachTerm: '', itunesResults: ''});
    //       break;
    //     default:
    //         break;
    // }
  }

  _onSelect(podcast){

    this.setState({itunesSeachTerm: '', itunesResults: ''});
    this.props.onSelect(podcast);
  }

  render() {
    return (
      <div style={{ padding:16, paddingTop:8, position:"relative", textAlign: 'left', display: 'flex', flexDirection: 'column', ...this.props.style }}>
        <input
          type="text"
          style={ styles.itunesSearch }
          placeholder="Search for any podcast"
          value={this.state.itunesSeachTerm}
          onChange={this._handleItunesTermChange} />
          {this.state.itunesSeachTerm &&
            <div style={{
              position:'absolute',
              top:16, right:16,
              fontWeight:'bold',
              fontSize:'150%',
              color:Colors.background}}
              onClick={() => this.setState({itunesSeachTerm:'', itunesResults:null})}
              >x</div>
          }
          {
            this.state.itunesResults &&
            <div style={{
                ...styles.autocompleteCnt
            }}>
              { this.state.itunesResults.map((r, k) => {
                return <SearchResult onHover={()=>this.setState({activeIndex:k})} active={this.state.activeIndex === k} onSelect={this._onSelect} key={k} data={r} />
              })}
            </div>
          }
      </div>
    )
  }


  _handleItunesTermChange(e){
    this.setState({itunesSeachTerm: e.currentTarget.value});
    this._preformItunesSearch(e.currentTarget.value);
  }

  _preformItunesSearch(term){
    console.log("SEARCHING FOR:", term);
    var self = this;
    ApiService.getITunesResults(term)
      .then((itunesResults) => {
        console.log(itunesResults);
        self.setState({itunesResults});
      });
  }
}
