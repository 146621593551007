import React from 'react';
import './App.css';

import Header from './components/Header';
import PodcastCitationWizard from './PodcastCitationWizard';

function App() {
  return (
    <div>
      <Header />
      <PodcastCitationWizard />
    </div>
  );
}

export default App;
