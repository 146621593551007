export const Colors = {
  background:'#FFFFFF',
  headline:'#1f1235',
  text: '#1b1425',
  button:'#ff6e6c',
  buttonText: '#1f1235',
  panelBackground: '#301e4e',
  panelHighlight: '#4c346b'
};

const baseTextStyle = {
  fontFamily: 'sans-serif',
  fontSize: '18px',
  lineHeight: 1.4,
  marginTop:0,
  color: Colors.text
}

export const TextStyles = {
  header: {
    ...baseTextStyle,
    fontWeight: 'bold',
    fontSize:'120%',
    marginTop: 0,
    marginBottom: 8
  },
  text: baseTextStyle,
  panelText: {
    ...baseTextStyle,
    color: Colors.background
  }
};

export const InputStyles = {
  default:{
    ...baseTextStyle,
    background:'none',
    border: 'none',
    borderBottom: '2px solid #C1C1C1',
    color:Colors.background,
  },
  label: {
    ...baseTextStyle,
    display: 'block',
    color: Colors.text,
    fontFamily: 'Rubik',
    fontSize: 11,
    letterSpacing: 0,
    marginBottom: 6
  },
  input: {
    // fontSize: 11,
    // width: 226,
    ...baseTextStyle,
    padding: '6px 8px',
    border: '1px solid #333',
    color: Colors.text,
    resize: 'vertical'
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  }
};



export const ContainerStyles = {
  appContainer: {
    margin:16,
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
  default: {
    padding:16
  },
  panel: {
    padding:16,
    background: Colors.panelBackground,
    borderRadius:6
  },
  inputContainer:{
    borderRadius:6,
    background:Colors.panelHighlight,
    fontSize:'80%',
    fontWeight:'bold',
    padding:12,
    margin:10,
    flexGrow:1
  }
}

export const ButtonStyles = {
  default: {
    ...baseTextStyle,
    border: `2px solid ${Colors.buttonText}`,
    cursor:"pointer",
    background:Colors.button,
    color: Colors.buttonText,
    padding:'5px 10px',
    display:'inline-block',
    borderRadius:'6px',
    fontWeight:"bold",
    marginTop:15
  },
  buttonGroup: {
    border: `2px solid ${Colors.buttonText}`,
    display:'flex',
    borderRadius:'6px',
    marginTop:5
  },
  buttonGroupButton:{
    ...baseTextStyle,
    fontWeight:"bold",
    padding:'5px 10px',
    flexGrow:1,
    textAlign:"center",
    cursor:"pointer",
    background:"rgba(255, 110, 108, 0.25)"
  },
  buttonGroupSelected:{
    background:Colors.button,
    cursor:"default"
  }
}
