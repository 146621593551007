import React, { Component } from 'react';
import moment from 'moment';


class Citation extends Component {
  constructor(props){
    super(props);
    this.style = {
  		textIndent: '-25px',
  		padding: '10px 10px 10px 35px',
  		lineHeight: '1.4em',
		   wordWrap: 'break-word'
    }

    this._renderAPA = this._renderAPA.bind(this);
    this._renderMLA8 = this._renderMLA8.bind(this);
    this._renderChicago = this._renderChicago.bind(this);
    this._renderHarvard = this._renderHarvard.bind(this);

  }

  _renderCitation(){
    console.log(this.props.style);
    return {
      'APA': this._renderAPA,
      'MLA 8': this._renderMLA8,
      'Chicago': this._renderChicago,
      'Harvard': this._renderHarvard
    }[this.props.style]();
  }

  _renderAPA(){
    console.log(this.props)
    const { speaker, date, episode, url } = this.props;
    const episodeDate = moment(date);

    let middleInitials = "";
    if(speaker.middle.length){
      middleInitials = speaker.middle.split(" ").map((n) => `${n.substring(0, 1)}.`);
    }
    const nameFormat = `${speaker.last}, ${speaker.first.substring(0, 1)}. ${middleInitials} (${speaker.title}).`;

    return (
      <p>
        {nameFormat} {episodeDate.format("(YYYY, MMMM D)")}. <em>{episode}</em> [Audio podcast]. Retrieved from {url}.
      </p>);
  }

  _renderMLA8(){
    const { speaker, date, podcast, episode, url, publisher } = this.props;
    const episodeDate = moment(date);

    const nameFormat = `${speaker.last}, ${speaker.first}, ${speaker.title}.`;

    return (
      <p>
        {nameFormat} “{episode}” <em>{podcast}</em>, {publisher}, {episodeDate.format("DD MMM. YYYY")}, {url}.
      </p>
    )
  }

  _renderChicago(){
    const { speaker, date, podcast, episode, url } = this.props;
    const episodeDate = moment(date);

    const nameFormat = `${speaker.last}, ${speaker.first}.`;

    return (
      <p>
        {nameFormat} “{episode}” <em>{podcast}</em>. Podcast audio, {episodeDate.format("MMMM D, YYYY")}. {url}.
      </p>
    )
  }

  _renderHarvard(){

  }

  render(){
    return (<div id="citation" style={this.style}>{this._renderCitation()}</div>);
  }
}

export default Citation;
