import React, { Component } from 'react';

import { Colors } from '../baseStyles';

class Header extends Component {
  constructor(props){
    super(props);
    this.style = {
      display:'flex',
      alignItems:"center",
      justifyContent:"center",
      height:'50px',
      background:Colors.headline,
      color:Colors.background,

      fontSize:'120%',
      fontWeight:'bold'
    }
  }
  render(){
    return (
      <div style={this.style}>
        <div>
          Podcast Citations
        </div>
      </div>
    );
  }
}

export default Header;
