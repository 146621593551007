import React, { Component } from 'react';

import { Colors, TextStyles } from '../baseStyles';

import PodcastEpisode from './PodcastEpisode';

const styles = {
  header:{
    display:'flex',
    alignItems:'center'
  },
  artwork: {
    alignSelf:'flex-start',
    width:58,
    minWidth:58,
    height:58,
    marginRight:15,
  },
  details: {
    flexGrow:1,
  },
  title: {
    color: Colors.text,
    fontSize:18,
  },
  summary: {
    color: Colors.text,
    fontSize:14
  },
  episodes: {
    paddingTop:16,
  },
  paginationCnt:{
    marginTop:15,
    display:'flex'
  },
  paginationItem:{
    flexGrow:1,
    flexBasis:0,
    color:'#272727',
    fontSize:14,
    textAlign:'center'
  },
  paginationLink:{
    cursor:'pointer'
  }
}

const EPS_PER_PAGE = 20;

export default class extends Component {

  constructor(){
    super();

    this.state = {
      currentPage:0,
      totalPages:0,
      hasVideo: false
    }

    this.eps = [];
  }

  componentDidMount(){

    this.props.podcast.rss.episodes.map((ep, key) => {
      console.log(ep);
      this.eps.push(<PodcastEpisode key={key} index={key} duration={false} episode={ep} onSelect={this.props.onSelect} />);
      return null;
    });

    this.setState({ totalPages: Math.ceil(this.eps.length/EPS_PER_PAGE) })
  }

  handlePage(currentPage){
    this.setState({currentPage});
  }

  renderPagingControls(){
    let { currentPage, totalPages } = this.state;
    return (<div style={styles.paginationCnt}>
        <div style={styles.paginationItem}>
          { currentPage > 0 &&
            <span style={styles.paginationLink} onClick={() => this.handlePage(0) }>First</span>
          }
        </div>
        <div style={styles.paginationItem}>
          { currentPage > 0 &&
            <span style={styles.paginationLink} onClick={() => this.handlePage(currentPage-1) }>Previous</span>
          }
        </div>
        <div style={styles.paginationItem}>{currentPage+1} / {totalPages}</div>
        <div style={styles.paginationItem}>
          { currentPage+1 < totalPages &&
            <span style={styles.paginationLink} onClick={() => this.handlePage(currentPage+1) }>Next</span>
          }
        </div>
        <div style={styles.paginationItem}>
          { currentPage+1 < totalPages &&
            <span style={styles.paginationLink} onClick={() => this.handlePage(totalPages-1) }>Last</span>
          }
        </div>
      </div>)
  }

  render() {
    let { currentPage } = this.state, startIndex = currentPage*EPS_PER_PAGE, endIndex = startIndex + EPS_PER_PAGE;
    return (
      <div style={{maxWidth:'800px', margin:'0 auto', padding:'32px 16px'}}>
        <div style={{...TextStyles.text, marginBottom:16, fontWeight:'bold', fontSize:'20px' }}>Select the episode to cite:</div>
        <div style={styles.header}>
          <div style={ styles.artwork }>
            <img alt="Podcast Artwork" src={ this.props.podcast.rss.channel.image } style={{ width:"100%", display:"block" }}/>
          </div>
          <div style={ styles.details }>
            <div style={ styles.title }>{ this.props.podcast.rss.channel.title }</div>
            <div style={ styles.summary }>{ this.props.podcast.rss.channel.author }</div>
          </div>
        </div>

        <div style={ styles.episodes }>
          { this.state.hasVideo &&
            <div style={{background:'#272727', color:'#fff', fontWeight:200, fontSize:14, fontFamily:'Rubik', padding:'5px'}}>This feed contains video content which has been filtered out.</div>
          }
          { this.eps.slice( startIndex, endIndex) }
          { this.state.totalPages > 0 && this.renderPagingControls() }
        </div>
      </div>
    )
  }
}
