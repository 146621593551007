import React, { Component } from 'react';

import { TextStyles, Colors } from '../baseStyles';

const styles = {
  episode: {
    padding:"12px 10px",
    borderTop:"1px solid #F3F3F3",
    clear:"both",
    display:'flex',
    cursor:"pointer"
  },
  common: {
    marginRight: 15,
    display:"inline-block",
    overflow:"hidden",
    whiteSpace:"nowrap",
    textOverflow:"ellipsis"
  },
  title: {
    flexGrow:1
  },
  duration:{
    minWidth:75,
    textAlign:'center'
  },
  date: {
    minWidth:75,
    fontSize:'80%',
    textAlign:'center'
  },
  hover:{
    backgroundColor: Colors.button,
    color:'#fff'
  }
};

export default class extends Component {
  constructor() {
    super();

    this.state = {
      hover:false
    }

    this.onClick = this.onClick.bind(this);
  }

  onClick(){
    this.props.onSelect(this.props.episode);
  }

  parseDuration(duration){
    if(!isNaN(duration)){
      let hours = this.padNumber(Math.floor(duration/(60*60)), 2);
      let minutes = this.padNumber(Math.floor(duration/60), 2);
      let seconds = this.padNumber(duration%60, 2);

      if(hours > 0){
        return [hours,minutes,seconds].join(":");
      }else{
        return [minutes,seconds].join(":");
      }
    }
    return duration;
  }

  padNumber(n, width, z = '0'){
    n = ''+n;
    while( n.length < width ){
      n = '' + z + n;
    }
    return n;
  }

  render() {
    var date = new Date(this.props.episode.updated).toLocaleDateString("en-US");
    let { title, duration } = this.props.episode;
    return (
      <div
        style={{ ...TextStyles.body, ...styles.episode, ...(this.state.hover) ? styles.hover : {}}}
        onMouseEnter={() => this.setState({hover:true})}
        onMouseLeave={() => this.setState({hover:false})}
        onClick={this.onClick}>
        <div style={{ ...styles.common, ...styles.title }} title={title}>{ title }</div>
        { !!this.props.duration && this.props.duration && <div style={{ ...styles.common, ...styles.duration }}>{this.parseDuration(duration)}</div> }
        <div style={{ ...styles.common, ...styles.date, marginRight:0 }}>{date}</div>
      </div>
    )
  }
}
